import { useQuery, gql } from '@apollo/client';
import { CourseReport, CourseReportSearch } from '../../__generated__/graphql';

export const GET_COURSE_REPORTS_BY_SUPPORT_WORKER_ID = gql`
  query GetCourseReportsBySupportWorkerId($query: CourseReportSearch) {
    GetCourseReportsBySupportWorkerId(query: $query) {
      id
      employeeCode
      teamId
      supportWorkerId
      courseName
      courseType
      courseArea
      status
      dateBooking
      dateCompleted
      dateExpiry
      createdAt
    }
  }
`;

interface GetCourseReportsBySupportWorkerIdResponse {
  GetCourseReportsBySupportWorkerId: CourseReport[];
}

export const useGetCourseReportsBySupportWorkerId = (query: CourseReportSearch) => {
  const { data, loading, error } = useQuery<GetCourseReportsBySupportWorkerIdResponse>(GET_COURSE_REPORTS_BY_SUPPORT_WORKER_ID, {
    variables: {
      query: { ...query },
    },
  });

  return { courseReports: data?.GetCourseReportsBySupportWorkerId || [], courseReportsLoading: loading, courseReportsError: error };
};
