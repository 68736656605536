import { useState } from 'react';
import { format } from 'date-fns';
import ReactSelect from 'components/Select';
import { Input } from 'components';
import DatePicker from 'components/DatePicker';
import { camelCaseToNormalString } from 'services/strings';
import { Maybe, CustomerPackage, CustomerPackagePrice } from '__generated__/graphql';
import { SelectOption } from 'types/types';
import ReactMultiSelect from 'components/MultiSelect';
import TextArea from 'components/TextArea';
import { fundingSources, priceTypes } from './Mapper';
import { packagePriceDateDoesNotFitPackageDate, packagePriceOverlapping, setStartOfDay } from './service';

interface PackagePriceUpdateProps {
  customerPackage: Maybe<CustomerPackage> | undefined;
  selectedPrice: CustomerPackagePrice;
  onClose: () => void;
  onUpdate: (data: CustomerPackagePrice) => void;
}

export default function PackagePriceUpdate({ customerPackage, selectedPrice, onClose, onUpdate }: PackagePriceUpdateProps) {
  const [validation, setValidation] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(selectedPrice?.startDate ? new Date(selectedPrice?.startDate) : null);
  const [monthlyValue, setMonthlyValue] = useState<string>(selectedPrice?.monthlyValue?.toString() || '');
  const [numberOfHours, setNumberOfHours] = useState<string>(selectedPrice?.numberOfHours?.toString() || '');
  const [note, setNote] = useState<string>(selectedPrice?.note || '');

  const [fundingSource, setFundingSource] = useState<SelectOption[]>(
    selectedPrice?.fundingSource ? selectedPrice?.fundingSource?.map((fs) => ({ value: fs || '', label: fs || '' })) : [],
  );

  const [priceType, setPriceType] = useState<SelectOption>({
    value: selectedPrice.type || '',
    label: camelCaseToNormalString(selectedPrice.type || ''),
  });

  const onSavePackagePrice = () => {
    if (!selectedPrice?.id) return;
    setValidation(null);
    const newStartDate = setStartOfDay(startDate);
    const hasOverlap = packagePriceOverlapping(customerPackage, newStartDate, selectedPrice?.id);
    console.log({ customerPackage });
    console.log({ hasOverlap });

    if (hasOverlap) {
      setValidation(
        `This date ${format(newStartDate, 'PPP')} overlaps with an existing package price ${format(customerPackage?.startDate || 0, 'PPP')} - ${
          customerPackage?.endDate ? format(customerPackage?.endDate || 0, 'PPP') : 'Active'
        }`,
      );
      return;
    }

    const fitsPackage = packagePriceDateDoesNotFitPackageDate(customerPackage, newStartDate);

    if (!fitsPackage) {
      setValidation(`This date  ${format(newStartDate, 'PPP')}  does not fit within the current package`);
      return;
    }

    if (startDate) {
      onUpdate({
        id: selectedPrice?.id,
        startDate: newStartDate,
        endDate: null,
        monthlyValue: parseFloat(monthlyValue || '0'),
        hourlyValue: parseFloat((parseFloat(monthlyValue) / (parseFloat(numberOfHours) * 4.34)).toFixed(2)),
        numberOfHours: parseFloat(numberOfHours || '0'),
        note,
        type: priceType.value,
        fundingSource: fundingSource.map((fs) => fs.value),
      });
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-[1200] outline-none focus:outline-none">
        <div className="relative w-auto mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[70vh] overflow-y-auto">
            <div className="text-gray-900 text-lg leading-lg font-semibold mb-5">Update Package Price</div>
            {validation && <div className="text-sm leading-lg mt-1 mb-4 text-red-800">{validation}</div>}
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Price Type</div>
              <div className="mt-2">
                <ReactSelect testId="package-price-types" options={priceTypes} onChange={(e) => setPriceType(e)} selectedValue={priceType} searchable />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Funding Source</div>
              <div className="mt-2">
                <ReactMultiSelect
                  testId="package-price-funding"
                  options={fundingSources}
                  onChange={setFundingSource}
                  selectedValue={fundingSource}
                  searchable
                />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Start Date</div>
              <div className="mt-2">
                <DatePicker testId="package-price-start-date" value={startDate ?? undefined} onChange={setStartDate} />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Monthly Value</div>
              <div className="mt-2">
                <Input value={monthlyValue} onChange={setMonthlyValue} testId="package-price-monthly-value" />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Number Hours per Week</div>
              <div className="mt-2">
                <Input value={numberOfHours} onChange={setNumberOfHours} testId="package-price-number-hours" />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Note</div>
              <div className="mt-2">
                <TextArea value={note} onChange={setNote} testId="package-price-note" rows={4} />
              </div>
            </div>
            <div className="flex justify-between items-center mt-5">
              <button type="button" className="text-gray-500 font-semibold text-md leading-md" onClick={onClose}>
                Cancel
              </button>
              <button
                type="button"
                data-cy="save-package-button"
                className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                onClick={onSavePackagePrice}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-gray-900" />
    </>
  );
}
